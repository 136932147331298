$shape-size-mobile: 22.5%;
$shape-size-small: 20%;

.shape-icon {
  width: $shape-size-mobile;
  height: auto;

  &--lightning {
    width: $shape-size-mobile * 0.6;
  }
}

@include from( map-get($bp, small) ) {

  .shape-icon {
    width: $shape-size-small;
    height: auto;

    &--lightning {
      width: $shape-size-small * 0.7;
    }
  }

}
