.link-list,
.content .link-list {
  margin: 0 0 2em 0;
  padding: 0;
  list-style: none;

  &__item {
    margin: 0 0 1em 0;
    padding: 0;
  }

  &__link {

  }

  a {
    @include fancy-link();
    text-shadow: none;
  }

  li::before {
    content: "";
  }

}
