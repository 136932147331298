.l-about {

  @include colour-scheme($color-secondary, $color-secondary-dark);
  @include selection($color-secondary-dark);

  &__title {
    @include grid-offset(0);
  }

  &__content {


      &-outer {
      }

      &-block {
        @include grid-col-full();
      }


  }

}


@include from(map-get($bp, mobile)) {

  .l-about {



    &__content {


    }

  }

}


@include from(map-get($bp, medium)) {

  .l-about {

    &__title {
    }

    &__content {

      &-block {
        @include grid-col-half();
        margin-bottom: 0;
      }
    }

  }

}

@include from(map-get($bp, large)) {

  .l-about {

    &__title {
    }

    &__content {

    }

  }

}
