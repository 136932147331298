$debug: false;
$nav-items-count: 4;
$nav-item-font-size: 0.7em;

.main-nav {
	@include grid-wrapper();

	margin-top: $grid-gutter-sm;
	margin-bottom: $grid-gutter-sm;

	font-family: $mono-font;

	z-index: $zi-layer-nav;

	&__logo {
		display: inline-block;
		float: left;
		width: 55px;
		margin-left: -3px;
		margin-right: -(50px / 3.5);
		margin-top: -10px;

		vertical-align: middle;
	}

	&__item {
		display: inline-block;
	}

	&__link {
		@include fancy-link();

		display: inline-block;
		margin: 0 0.75em;

		font-size: $nav-item-font-size;
		line-height: $line-height-feature;
		text-shadow: none;
		text-transform: lowercase;

		background-image: link-line(#000, 2), link-line-highlight($color-highlight);

		&:focus {
			background-position: 0 0, 201% 0;
		}
	}
}

@include from(map-get($bp, small)) {

	$grid-offset: $grid-row-padding-sm - $grid-gutter-sm;

	.main-nav {

		position: fixed;
		top: 0;
		left: 0;
		right: 0;

		margin-top: 0;
		margin-bottom: 0;

		@if ($debug) {
			height: 10px;
			border: 1px solid red;
		}

		&,
		&-wrapper {
			height: 1px;
			overflow: visible;
		}

		&-wrapper {
			@include grid-row();
		}

		&__inner {
			@include grid-col(2);
			float: none;

			&:before {
				content: "";
				display: block;
				border-top: $shape-border-thick solid $color-highlight;
			}

		}

		&__logo {
			display: inline-block;
			float: none;
			width: 100%;
			height: $spacing-vertical-base;
			margin-left: -7%;

			transition: ($transition-base-speed * 2) width $transition-base-ease;

			.rabbit {
				position: absolute;
				left: 0;
				bottom: 2rem;
				width: 120%;
				min-width: 40px;
				max-width: 150px;
			}

		}

		&__nav {
			// optical typography fudge
			margin-top: 0.2rem;
		}

		&__item {
			display: block;
			margin: 0;
			height: (get-font-size(h1_title, small) * 2) / $nav-items-count;
		}

		&__link {
			margin: 0;
			background-image: none, link-line-highlight($color-highlight);

			.main-nav__item.-is-current & {
				background-image: link-line(#000, 1), link-line-highlight($color-highlight);
			}
		}
	}
}

@include from($grid-max-width) {

	.main-nav {
		left: 50%;
		right: auto;
		width: $grid-max-width;
		transform: translateX(-50%);
	}

}


@include from(map-get($bp, medium)) {
	.main-nav {

		&__logo {

			.rabbit {
			}

		}

		&__item {
			display: block;
			height: (get-font-size(h1_title, medium) * 2.2) / $nav-items-count;
		}

	}
}


@include from(map-get($bp, large)) {
	.main-nav {

		&__logo {

		}

	}
}


@include from(map-get($bp, xlarge)) {

	.main-nav {

		&__item {
			display: block;
			height: (get-font-size(h1_title, medium) * 2.2 * 1.125) / $nav-items-count;
		}

	}

}
