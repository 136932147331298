.l-teaser {

  h1 {
    margin: 0.5em 0 0.5em 0;
    font-size: 3.4em;
  }

  h2 {
    margin: 2em 0 1em 0;
    font-size: 2em;
  }

  h2 + .u-small {
    //margin-top: -1em;
  }

  h3 {
    font-size: 1.5em;
  }

  p {
    font-size: 0.95em;
  }

  p.lede {
    font-size: 1.2em;
  }

  p.note {
    margin: 1em 0 1em 0;
    line-height: 1.75;

    &:before {
      @include heading-base();
      content: "*";
      display: block;
      font-size: 3em;
      line-height: 1;
    }
  }

  .logo {
    position: relative;
    width: 9em;
    height: 9em;

    margin: -1% 0 0 -0.2em;

    .rabbit {
      width: auto;
      height: 100%;
      margin: auto;
      padding: 10%;
      padding-right: 15%;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;

      display: block;
      width: 100%;
      height: 100%;
      background-color: $color-highlight;
      opacity: 0.8;

      border-radius: 100%;
      transform: translateX(-50%) translateY(-48%);
      transition: 0.25s opacity ease-in;

      z-index: -1;
    }

    &:hover:before {
      opacity: 0.25;
    }
  }

  .intro {
    margin: 5vh auto;

    p {
      margin-bottom: 0.5em;
    }

    a {
      @include fancy-link();
      text-shadow: none;
    }
  }

  .container {
    width: 90vw;
    max-width: 550px;
    margin: auto;
    padding: 5vh 0;
  }

  .content {

    &:nth-of-type(2n+1) {
      text-align: right;
    }

  }

  .link-list {


    &__item {
      margin: 2em 0;
    }

    .u-small {
      margin: 0.5em 0;
    }

  }

  .u-small {
    font-size: 11px;
  }

}

@include from(map-get($bp, small)) {
  .l-teaser {

    p {
      font-size: 0.85em;
    }

    p.lede {
      font-size: 1.1em;
    }

    .logo {

      margin: 0 0 0 -0.2em;
    }

  }
}
