@mixin grid-edge-offset($edge: 'left', $selector: '> *', $grid-size: 4) {

  $pos: 'right';
  $grid-prefix: 0;
  $offset: 50%;

  @if $edge == 'right' {
    $pos: 'left';
    $grid-prefix: $grid-columns - $grid-size;
    $offset: -50%;
  }

  @include from(map-get($bp, small)) {

    @include grid-prefix($grid-prefix);
    @include grid-col($grid-size);

    #{$selector} {
      position: absolute;
      top: 0;
      #{$pos}: $grid-gutter-sm / 2;
      transform: translateX($offset);
    }

  }

  @include from(map-get($bp, medium)) {
    #{$selector} {
      #{$pos}: -$grid-gutter-md / 2;
    }
  }

  @include from(map-get($bp, large)) {
    #{$selector} {
      #{$pos}: -$grid-gutter-lg / 2;
    }
  }

  @include from(map-get($bp, xlarge)) {
    #{$selector} {
      #{$pos}: -$grid-gutter-xlg / 2;
    }
  }
}
