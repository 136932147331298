/*
* Hide only visually, but have it available for screen readers: h5bp.com/v
*/

@mixin visually-hidden() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  width: 1px;

  &.focusable:active,
  &.focusable:focus {
    @include visually-hidden-undo();
  }

}

@mixin visually-hidden-undo() {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.u-visually-hidden {
  @include visually-hidden;
}

.u-mobile-show {
  @include from(map-get($bp, mobile)) {
    @include visually-hidden;
  }
}

.u-wrap-helper {
	@include from(map-get($bp, mobile)) {
    display: inline-block;
	}
}

.u-no-wrap {
  white-space: nowrap;
}

.u-handle {
  position: relative;
  margin-left: 0.75em;

  &:before {
    content: "@";
    position: absolute;
    margin-left: -0.75em;
  }

  &:hover:before {
    color: $color-highlight;
  }

  &:hover ~ .u-handle-service {
    font-style: italic;
  }
}


.u-subtitle {
	display: block;
}

.u-code {
	font-family: $mono-font;
}

.u-subtle {
  opacity: 0.5;
}

.u-small {
  @include set-font-size(meta);

  a {
    padding: 0.2em 0 !important;
  }
}

.u-norm {
	font-size: 1rem;
	font-weight: normal;
	letter-spacing: normal;
}

.u-note,
p.u-note {
  display: inline-block;
  @include set-font-size(meta);
  font-family: $mono-font;

  background-color: rgba($color-secondary, 0.25);
  padding: 1rem;
  margin-bottom: 3rem;
}

.u-external-link {
  &::after {
    display: inline-block;
    vertical-align: middle;
    width: 0.75em;
    height: 0.75em;
    margin-left: 0.15em;
    margin-top: -0.15em;
    content: "";
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><g stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" fill="none" stroke="currentColor"><path d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6M15 3h6v6M10 14L21 3"/></g></svg>');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.25;
  }
}

.u-full-width-breakout {

  margin: 0 -($grid-row-padding-mobile / 2);

  @include from(map-get($bp, small)) {
    width: 100vw !important;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
}

.u-text-align-center {
  text-align: center;
}
