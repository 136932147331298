@function link-line($color, $extra-thickness: 0) {

	$gradient-line-start: 90.5%;
	$gradient-line-size: 7.5%;
	$gradient-line-end:	$gradient-line-start + $gradient-line-size;

	@return linear-gradient(
			rgba($color, 0) $gradient-line-start,
			rgba($color, 1) $gradient-line-start + 0.1%,
			rgba($color, 1) $gradient-line-end + ($extra-thickness * 1%),
			rgba($color, 0) $gradient-line-end + ($extra-thickness * 1% + 0.1%)
		);
}

@function link-line-highlight($color) {
	@return linear-gradient(
			to right,
			rgba($color, 0.65) 0%,
			rgba($color, 0.65) 50%,
			rgba($color, 0) 50%,
			rgba($color, 0) 100%
		);
}

@mixin fancy-link($inverse: false, $bg: $color-bg, $highlight: $color-highlight) {

  color: inherit;

	padding: 0.1em 0;

  text-decoration: none;
  text-shadow: 0 2px 0 $bg;

  transition: 0.25s background-position linear, 0.25s 0s text-shadow linear;

  background-repeat: no-repeat;
  background-position: 0 0, 201% 0;
  background-size: 100% 100%, 200% 100%;

	will-change: background-position, text-shadow;

	@if ($inverse) {
		background-image: link-line(#fff), link-line-highlight($highlight);
	}
	@else {
		background-image: link-line(#000), link-line-highlight($highlight);
	}

  &:hover,
  &:focus,
  &:active {
    transition: 0.5s background-position ease-in-out;
    text-shadow: none;
    background-position: 0 0.5em, 0 50%;

		@if ($inverse) {
			color: #fff;
		}
		@else {
			color: #000;
		}

  }

	&::selection {
		text-shadow: none;
	}

	&::-moz-selection {
		text-shadow: none;
	}

}
