/**
 * Media Queries - *Try* and make everything fit within these - use @from/to/between
 */


$bp: (
  mobile: 500px,
  small: 700px,
  medium: 1000px,
  large: 1280px,
  xlarge: 1400px,
  xxlarge: 1800px
);


/**
 * Font definitions
 */


$body-font: "Heebo", "Droid Sans", "Open Sans", "San Francisco", Helvetica, Arial, sans-serif;
$heading-font: "BW Quinta Pro", "Lato", "Droid Sans", "Open Sans", "San Francisco", Helvetica, Arial, sans-serif;

$mono-font: "Droid Sans Mono", "Andale Mono", "Monaco", "Consolas", "Lucida Console", monospace;

$letter-spacing-heading: 0;
$letter-spacing-heading-large: -0.025em;
$letter-spacing-uppercase: 0.02em;
$letter-spacing-p-lede: -0.01em;

$line-height-body: 1.7;
$line-height-heading: 1.1;
$line-height-feature: 1.7;

$heading-lhs-kerning-tweak: -0.025em;


/**
 * Transitions
 */
$transition-base-speed: 250ms;
$transition-base-ease: ease-in-out;
$transition-base: $transition-base-speed all $transition-base-ease;


/**
 * Padding and sizing
 */

$spacing-base: 3vw;
$spacing-vertical-base: 25vh;
$spacing-vertical-base-sm: 5vh;


/**
 * Feature shapes
 */

$shape-base-size: 90vh;
$shape-size-mobile: 80vw;

$shape-border-thin: 5px;
$shape-border-thick: 10px;

/**
 * Visual effects
 */

$box-shadow-base: 0 0 50px 0 rgba(#000, 0.2);
$box-shadow-mid: 0 0 50px 0 rgba(#000, 0.35);
$box-shadow-stronger: 0 0 50px 0 rgba(#000, 0.5);

/**
 * Raw Colors - Try not to use these within actual styles!
 *
 * Generate color names here: http://toolery.me
 */


$light-cream: #F8F8F7;
$yellow: #FFF954;
$teal: #4CBEC1;
$purple: #7352B8;

$aqua: #9EFAF0;
$pink: #FF8586;
$pale-yellow: #FFFDB5;

/**
 * Purpose-based colors
 */


$color-text: #2b2b2e;

$color-highlight: $yellow;
$color-highlight-bright: lighten($color-highlight, 5%);

$color-primary: $pale-yellow;
$color-primary-bright: lighten($color-primary, 5%);
$color-primary-dark: darken(saturate($color-primary, 15%), 33%);

$color-secondary: $aqua;
$color-secondary-bright: lighten($color-secondary, 5%);
$color-secondary-dark: darken(saturate($color-secondary, 15%), 33%);

$color-tertiary: $pink;
$color-tertiary-bright: lighten($color-tertiary, 5%);
$color-tertiary-dark: darken(saturate($color-tertiary, 15%), 33%);


$color-bg: #fff;

$color-selection-bg: rgba($color-highlight-bright, 0.8);
$color-selection-bg-secondary: rgba($color-secondary-bright, 0.8);
$color-selection-bg-tertiary: rgba($color-tertiary-bright, 0.8);


/**
 * z-index stack
 */
$zi-layer-1: 20;
$zi-layer-2: 40;
$zi-layer-3: 60;
$zi-layer-4: 80;
$zi-layer-nav: 1;


/**
 * Misc
 */
