.page {
  padding-bottom: $spacing-vertical-base-sm;
  color: mix($color-text, $color-bg, 85%);

  &--has-banner {
    .page {
      &__wrapper {
        margin-top: -1.5em;
      }

      &__meta:first-child {
        margin-top: -4em;
      }

      &__title:last-child {
        margin-bottom: 0;
      }

    }
  }

  &__banner {
    position: relative;
    height: 25vh;
    min-height: 200px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &__banner-attribution {
    position: absolute;
    top: $grid-gutter-sm;
    right: $grid-gutter-sm;

    font-family: $mono-font;
    font-size: 0.5em;
    text-align: right;
    color: $color-text;
    opacity: 0.5;

    @include from(map-get($bp, medium)) {
      top: auto;
      bottom: -$grid-gutter-md;
      right: $grid-gutter-md;
    }
  }


  &__wrapper {
    @include grid-wrapper();
    max-width: $grid-max-width;
    margin: auto;
  }

  &__inner {
    @include grid-row();
  }

  &__header {
    @include clearfix();
    @include grid-col-full();
		margin-bottom: $spacing-vertical-base-sm;
  }

	&__header-left {
		padding-top: $spacing-vertical-base-sm;
		margin-bottom: $spacing-vertical-base-sm;

    &:only-child {
      margin-bottom: 0;
    }
	}

  &__meta,
  &__title {

    > span {
      background-color: mix($color-bg, $color-highlight, 30%);
      box-decoration-break: clone;
      padding: 0 0.1em;
    }

    > span span {
      position: relative;
    }

    a {
      text-shadow: none;
    }

  }

  &__title {

  }

  &__meta {
    @include heading-mono()
    @include set-font-size(meta);

    &:first-child {
      // fudge to make line up with menu on left
      margin-top: 0.4em;
    }
  }

  &__content {
    @include grid-col-full();
    @include set-font-size(content);

    float: none;

    .lede {
      font-size: get-font-size(lede) * 1.15;
      color: #000;
    }

  }

	&__col {
		margin-top: $spacing-vertical-base-sm;
	}

	&__col--two {
		columns: 2;
		margin-bottom: $spacing-vertical-base-sm;
	}


  &__details {

    &-title {

    }

  }

  &__main {
    @include clearfix();
    clear: both;
  }

  &__footer {

    &:before {
      content: "";
      display: block;
      width: 3rem;
      height: 3rem;
      background: url('/assets/img/lightning.svg') no-repeat;
      background-size: contain;

      margin-bottom: $spacing-vertical-base-sm;
    }
  }

}

@include to(map-get($bp, small)) {
  .page {
    &__banner {
      &--mobile-centered {
        background-size: 120% auto;
      }
    }
  }
}

@include from(map-get($bp, small)) {

  .page {

    padding-bottom: $spacing-vertical-base / 4;

    &--has-banner {
      .page {

        &__wrapper {
          margin-top: -2.25em;
        }

        &__header,
				&__header-left {
          padding-top: 0;
        }

        &__meta:first-child {
          margin-top: -6.5em;
        }

        &__meta + .page__title {
          margin-top: 1em;
        }
      }
    }

    &__banner {
      height: 50vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    &__header {
      @include grid-prefix(2);
      @include grid-suffix(2);
      @include grid-col(8);

      padding-top: $spacing-vertical-base;

      margin-bottom: 0;
      margin-bottom: $spacing-vertical-base / 4;

    }

		&__header-left {
			padding-top: $spacing-vertical-base * 1.5;

      margin-bottom: 0;
      margin-bottom: $spacing-vertical-base / 4;
		}

    &__content {
      @include grid-prefix(2);
      @include grid-suffix(2);
      @include grid-col(8);

      float: none;
      margin-bottom: $spacing-vertical-base / 4;
    }

    &__footer {
      &:before {
        margin-bottom: $spacing-vertical-base / 4;
      }
    }
  }

}

@include from(map-get($bp, medium)) {

  .page {

    &__header {
      @include grid-prefix(2.75);
      @include grid-suffix(2.75);
      @include grid-col(6.5);
    }

		&__header {
			// note: these styles need to be separate or they'll get overridden by the @includes above
			padding-top: $spacing-vertical-base;
      margin-bottom: 0;
      margin-bottom: $spacing-vertical-base / 4;
		}

		&__header-left {
			padding-top: $spacing-vertical-base * 1.5;

      margin-bottom: 0;
      margin-bottom: $spacing-vertical-base / 4;
		}

    &__content {
      @include grid-prefix(2.75);
      @include grid-suffix(2.75);
      @include grid-col(6.5);

      float: none;
      margin-bottom: $spacing-vertical-base / 4;

      .lede {
        //margin: $spacing-vertical-base / 4 0;
      }

    }

		&__col {
			margin: $spacing-vertical-base / 4 0;
		}
  }

}
