@font-face {
    font-family: 'BW Quinta Pro';
    src: url('../fonts/bwquintapro-black-webfont.eot');
    src: url('../fonts/bwquintapro-black-webfont.eot?#iefix') format('emfonts/bedded-opentype'),
         url('../fonts/bwquintapro-black-webfont.woff2') format('woff2'),
         url('../fonts/bwquintapro-black-webfont.woff') format('woff'),
         url('../fonts/bwquintapro-black-webfont.ttf') format('truetype'),
         url('../fonts/bwquintapro-black-webfont.svg#bw_quinta_problack') format('svg');
    font-weight: 800;
    font-style: normal;

}
