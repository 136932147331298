$grid-max-width: 1800px;

$grid-columns: 12;
$grid-col-size: 100% / $grid-columns;

$grid-gutter-mobile: 4%;
$grid-gutter-sm: 1rem;
$grid-gutter-md: 2rem;
$grid-gutter-lg: 4rem;
$grid-gutter-xlg: 5rem;

$grid-row-padding-mobile: $grid-gutter-mobile;
$grid-row-padding-sm: 2rem;
$grid-row-padding-md: 2.5rem;
$grid-row-padding-lg: 3rem;

@mixin grid-wrapper {
  @include clearfix();
  padding: 0 $grid-row-padding-mobile;

  @include from(map-get($bp, small)) {
    padding: 0 $grid-row-padding-sm;
  }

  @include from(map-get($bp, large)) {
    padding: 0 $grid-row-padding-lg;
  }
}

@mixin grid-row {
  @include clearfix();
  display: block;
  clear: left;
  margin: auto;

  margin-left: ceil($grid-gutter-mobile / -2);
  margin-right: floor($grid-gutter-mobile / -2);

  @include from(map-get($bp, small)) {
    margin-left: $grid-gutter-sm / -2;
    margin-right: $grid-gutter-sm / -2;
  }

  @include from(map-get($bp, medium)) {
    margin-left: $grid-gutter-md / -2;
    margin-right: $grid-gutter-md / -2;
  }

  @include from(map-get($bp, large)) {
    margin-left: $grid-gutter-lg / -2;
    margin-right: $grid-gutter-lg / -2;
  }

  @include from(map-get($bp, xlarge)) {
    margin-left: $grid-gutter-xlg / -2;
    margin-right: $grid-gutter-xlg / -2;
  }
}

@mixin grid-col($span, $total: $grid-columns) {
  position: relative;
  float: left;
  width: 100% / $total * $span;
  min-height: 1px;
  padding: 0 ($grid-gutter-mobile / 2);

  @include from(map-get($bp, small)) {
    padding: 0 ($grid-gutter-sm / 2);
  }

  @include from(map-get($bp, medium)) {
    padding: 0 ($grid-gutter-md / 2);
  }

  @include from(map-get($bp, large)) {
    padding: 0 ($grid-gutter-lg / 2);
  }

  @include from(map-get($bp, xlarge)) {
    padding: 0 ($grid-gutter-xlg / 2);
  }
}

@mixin grid-col-full() {
  @include grid-col($grid-columns);
}

@mixin grid-col-half() {
  @include grid-col($grid-columns / 2);
}

@mixin grid-col-quarter() {
  @include grid-col($grid-columns / 4);
}

@mixin grid-col-third() {
  @include grid-col($grid-columns / 3);
}

@mixin grid-prefix($span, $total: $grid-columns) {
  margin-left: 100% / $total * $span;
}

@mixin grid-suffix($span, $total: $grid-columns) {
  margin-right: 100% / $total * $span;
}

@mixin grid-offset($span, $total: $grid-columns) {
  @include grid-prefix($span, $total);
}


// Test classes

.grid, .grid-row {
  @include grid-row();
}

@for $c from 1 to $grid-columns + 1 {
  .grid-col-#{$c}     { @include grid-col($c); }
  .grid-offset-#{$c}  { @include grid-offset($c); }
}

.grid-test-col {
  p {
    height: 100vh;
    background-color: rgba(pink, 0.25);
  }
}

.grid-overlay {
  @include grid-wrapper();

  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: $grid-max-width;
  pointer-events: none;

  transform: translateX(-50%);

  .grid-col:nth-child(-n+2),
  .grid-col:nth-child(n+11) {
    p {
      background-color: rgba(yellow, 0.25);
    }
  }

}
