.l-contact {

  @include colour-scheme($color-primary, $color-primary-dark);
  @include selection($color-primary-dark);

  .u-handle:hover:before {
    color: $color-primary;
  }

  &__content {
    &-block {
      @include grid-col-full();
    }
  }
}


@include from(map-get($bp, mobile)) {
  .l-contact {
    &__content {
      &-block {
        @include grid-col-half();
      }
    }
  }
}

@include from(map-get($bp, small)) {

  .l-contact {
    min-height: 100vh;
  }

}


@include from(map-get($bp, medium)) {

  .l-contact {

    &__title {
    }

    &__content {


    }

  }

}

@include from(map-get($bp, large)) {
  .l-contact {

    &__title {
    }

    &__content {


    }
  }
}
