.section {
  @include clearfix();

  padding: $spacing-vertical-base-sm 0;

  &__wrapper {
    @include grid-wrapper();
  }

  &__row {
    @include grid-row();
  }

  &__content {
    @include grid-col(12);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__header {
    @include grid-col-full();
  }

  &__title {
    @include set-font-size(h1);
    @include heading-base();
  }

	&__title--small {
		@include heading-mono();
		@include set-font-size(meta);
	}

  &__subtitle {
    font-family: $mono-font;
    font-size: 0.8em;

    margin-top: -0.5rem;
    margin-bottom: $spacing-vertical-base / 4;
  }


}

@include from(map-get($bp, small)) {
  .section {
    display: flex;
    min-height: 99vh;

    padding: 0;

    &__header {
    }

    &__subtitle {
      margin-top: -$spacing-vertical-base / 6;
    }

    &__wrapper {
      flex: none;
      width: 100%;
      max-width: $grid-max-width;
      margin: auto;
    }

    &__content {
      @include grid-col(8);
      @include grid-prefix(2);
      @include grid-suffix(2);
    }

  }
}
