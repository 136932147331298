*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	background: mix($color-bg, $color-highlight, 35%);
}

body {
	margin: 0;
	padding: 0;
	background-color: $color-bg;
}

svg {
	display: block;
}

.wrapper {
	position: relative;
	width: 100vw;
	overflow-x: hidden;
}

.content {
	// @TODO: Delete when deleting teaser page
	@include clearfix();
}
