/* ------------------------------------------------------------------------------------------------

Shame
=====

1. 	If it’s a hack, it goes in shame.css.
2. 	Document all hacks fully:
  a. 	What part of the codebase does it relate to?
  b. 	Why was this needed?
  c. 	How does this fix it?
  d. 	How might you fix it properly, given more time?
3. 	Do not blame the developer; if they explained why they had to do it then their reasons are
probably (hopefully) valid.
4. 	Try and clean shame.css up when you have some down time.

via: http://csswizardry.com/2013/04/shame-css/

------------------------------------------------------------------------------------------------ */

