.l-intro {
	display: flex;
	position: relative;
	min-height: 60vh;
	align-items: center;

	&__content {
		@include grid-col-full();
	}

	&__wrapper {
		margin: 0 auto;
	}

	&__banner {

		&-shape {
			fill: $color-tertiary;
		}

		&-shape-container {
			@include grid-edge-offset('right', '.shape');
		}

		.shape-icon--lightning use {
			stroke: $color-highlight;
		}

		.shape-icon--circle use {
			stroke: $color-secondary;
		}

		&-shapes {

			.shape-icon:nth-child(1) {
				position: absolute;
				bottom: 2%;
				left: 30%;
			}

			.shape-icon:nth-child(2) {
				position: absolute;
				bottom: 12%;
				right: 25%;
			}

		}
	}

	&__welcome {

		.content & {
			// increase specificity :-\
			@include set-font-size(h1_title);
		}
		line-height: 1;

		&:after {
			content: "";
			display: block;
			width: 5rem;
			box-sizing: border-box;
			margin: 1.85rem 0 1.85rem ($heading-lhs-kerning-tweak * -1);
			border-top: $shape-border-thin solid $color-secondary;
		}
	}

}


@include to(map-get($bp, small)) {

	.l-intro {

		$shapeSize: 60vw;

		&__banner {
			position: relative;

			&-shapes {

				.shape-icon:nth-child(1) {
					position: absolute;
					bottom: 0%;
					left: 27%;
				}

				.shape-icon:nth-child(2) {
					position: absolute;
					bottom: 17%;
					right: 20%;
				}

			}
		}

		.shape {
			position: absolute;
			top: -17vw;
			right: -10vw;

			width: $shapeSize;
			height: $shapeSize;

			transform: none;
		}

		.shape__image {
			x: -40%;
			y: -25%;
			width: 150%;
			height: 150%;
		}

		.shape--shape {
			transform: translate(0%, 10%) scale(0.9);
		}

		&__welcome {
			padding-top: 0;
			margin-top: 0;
			z-index: $zi-layer-1;

			.u-wrap-helper {
				display: block;
			}
		}
	}
}

@include from(map-get($bp, small)) {

	.l-intro {
		min-height: 99vh;
		padding: 0;
		padding-bottom: ($spacing-vertical-base);
		margin-bottom: -($spacing-vertical-base / 3);

		&__content {
			@include grid-col(4);
			@include grid-prefix(2);
			@include grid-suffix(6);
		}

		&__row {
			position: relative;
		}

		&__banner {
			@include grid-col(6);
			@include grid-prefix(6);

			position: absolute;
			top: $spacing-vertical-base;

			&-shape-wrapper {
				@include grid-row();
			}

			.shape {
				position: absolute;
				transform: translate(-50%, 0%);
			}

			.shape--shape {
				transform: translate(-55%, 5%);
			}

		}


		&__welcome {

			margin-top: calc(#{$spacing-vertical-base} + 0.2rem);
			padding-top: $spacing-vertical-base;
			padding-right: $grid-gutter-sm;

			&:after {
				width: 50%;
				margin: 2rem 0 2.25rem ($heading-lhs-kerning-tweak * -1);
				border-top-width: $shape-border-thick;
			}
		}
	}

}

@include from(map-get($bp, medium)) {

	.l-intro {

		padding-bottom: ($spacing-vertical-base / 1.5);
		margin-bottom: -($spacing-vertical-base / 3);

		&__content {

		}

		&__welcome {
			padding-right: $grid-gutter-md;
		}

		&__banner {


		}

	}

}

@include from(map-get($bp, large)) {

	.l-intro {

		&__welcome {
			padding-right: $grid-gutter-lg;
		}

		&__banner {

		}

	}

}

@include from(map-get($bp, xlarge)) {

	.l-intro {

		&__content {
			font-size: 1.1em;
		}

		&__welcome {
			padding-right: $grid-gutter-xlg;
		}

		&__banner {

		}

	}

}
