.l-work {

  &__header {
    padding-top: $spacing-vertical-base-sm;

  }

  @include from(map-get($bp, small)) {
    display: block;

    &__header {
      padding-top: $spacing-vertical-base / 2;
      padding-bottom: $spacing-vertical-base-sm;
    }
  }
}

.l-work__more.section {
  margin: 0;
  height: auto;

  @include from(map-get($bp, medium)) {

    margin: auto;

    .section__wrapper {
      @include grid-col(6);
      @include grid-prefix(6);
    }

    .section__content {
      @include grid-col-full();
      @include grid-prefix(0);
      @include grid-suffix(0);
    }

    .section__title {
      @include set-font-size(h2);
    }
  }
}

.l-work__other.section {

  margin: 0;

  .section__title {
    @include set-font-size(h2);
  }

  .project {
    padding-bottom: $spacing-vertical-base / 2;
  }

  @include from(map-get($bp, medium)) {

    margin: auto;

    .section__wrapper {
      @include grid-col(6);
      @include grid-prefix(6);
    }

    .section__content {
      @include grid-col-full();
      @include grid-prefix(0);
      @include grid-suffix(0);
    }

    .section__title {
      @include set-font-size(h2);
    }
  }

}