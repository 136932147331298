@import "config";
@import "mixins/mixin-loader";

// Global
@import "global/normalize";
@import "global/animations";
@import "global/document";
@import "global/fonts";
@import "global/forms";
@import "global/grid";
@import "global/icons";
@import "global/typography";

// Vendor - CSS needed for plugins to work. Eg. iDangerous.scss

// Utilities - Classes used to modify the element it appears on
@import "global/utilities";

// Components - The smallest practical part of a larger layout
@import "components/buttons";
@import "components/image-block";
@import "components/link-list";
@import "components/logo";
@import "components/main-nav";
@import "components/section";
@import "components/page";
@import "components/pages-list";
@import "components/project";
@import "components/shape";
@import "components/shape-icon";


// Locations - Made up of a collection of components
@import "locations/header";
@import "locations/intro";
@import "locations/about";
@import "locations/work";
@import "locations/contact";
@import "locations/teaser";

@import "shame";
