$anim_base: 76;
$anim_duration: $anim_base * 1s;

// x% = 1 sec, used for percentage-based animation keyframes
$anim_frame: 1 / $anim_base * 100%;
$anim_jot: $anim_frame * 0.2;


$rabbit_ratio: 0.689;
$rabbit_size: 200px;
$speech_bubble_ratio: 1.505;
$speech_bubble_size: 45%;

.logo {

  position: relative;
  width: 100%;
  height: auto;

  .test & {
    width: 500px * $rabbit_ratio;
    height: 500px;
  }

  .rabbit {
    width: 100%;
    height: 100%;
  }

  .rabbit-speech {
    display: none;
  }

  @for $i from 1 through 4 {
    &[data-rabbit='#{$i}']:hover {
      .rabbit-speech__speak:nth-child(#{$i}) {
        display: block;
      }
    }
  }

}


@include from(map-get($bp, small)) {
  .logo {

    .rabbit {

      & > * {
        transform-box: fill-box;
      }

      &__eye_open,
      &__eye_closed {
        opacity: 1;
        visibility: visible;
      }

      &__eye_closed_hover {
        opacity: 0;
        visibility: visible;
      }

      &__eye_open {
        visibility: visible;
        animation: $anim_duration blink-in step-end none infinite;
      }

      &__eye_closed {
        visibility: hidden;
        animation: $anim_duration blink-out step-end none infinite;
      }

      &__ear-left {
        animation: $anim_duration ear-twitch-left step-end infinite;
        transform-origin: 20% bottom;
      }

      &__ear-right {
        animation: $anim_duration ear-twitch-right step-end infinite;
        transform-origin: 20% bottom;
      }

    }

    .rabbit-speech {
      display: block;

      opacity: 0;
      transition: $transition-base-speed opacity $transition-base-ease;
      transition: 0.25s all ease-in-out;
      will-change: opacity;

      &__inner {
        transform-origin: bottom left;
        transform: scale(0.5);

        transition: $transition-base-speed * 0.8 transform $transition-base-ease;
        transition: 0.25s all ease-in-out;

        will-change: transform;
      }

      &__speak {
        display: none;
      }
    }

    &:hover {

      .rabbit {

        &__eye_open,
        &__eye_closed {
          animation-play-state: paused;
          opacity: 0;
        }

        &__eye_closed_hover {
          opacity: 1;
          visibility: visible;
        }

      }

      .rabbit-speech {
        opacity: 1;

        &__inner {
          transform: scale(1.2);
        }
      }

    }
  }
}


$left-twitch: 5 27 39 53 65;
$right-twitch: 3 11 57 61 73;

@each $anim in ('left' 'right') {

  @keyframes ear-twitch-#{$anim} {

    $twitch: $left-twitch;
    $twitch_rotation: -6deg;

    @if ($anim == 'right') {
      $twitch: $right-twitch;
      $twitch_rotation: 4deg;
    }

    @each $n in ($twitch) {

        #{$n * 1%} {
          transform: rotate($twitch_rotation);
        }

        #{$n + ($anim_frame * (1 + random(2)) ) } {
          transform: rotate(0deg);
        }

    }

  }
}

$blinks: 3 5 9 11 27 30 41 55 63 67 71 79 87 99;

@each $anim in ('in' 'out') {

  $a: hidden;
  $b: visible;

  @if ($anim == 'out') {
    $a: visible;
    $b: hidden;
  }

  @keyframes blink-#{$anim} {

    @each $n in ($blinks) {
      #{$n - $anim_jot}   {  visibility: $b; }
      #{$n * 1%}          {  visibility: $a; }
      #{$n + $anim_jot}   {  visibility: $b; }
    }

    100% {  visibility: $b; }

  }

}
