$imageRatio: 300 / 200;  // width / height

.pages-list {
	position: relative;
	padding: 0;
	margin: $spacing-vertical-base-sm 0;

	display: flex;
	flex-wrap: wrap;
	align-items: stretch;

	transition: $transition-base-speed opacity $transition-base-ease;

	@include from(map-get($bp, small)) {
		margin: $spacing-vertical-base / 4 0;
	}

	@include from(map-get($bp, xxlarge)) {
		margin: $spacing-vertical-base / 4 0;
	}

	&__item {
		display: block;
		flex: 0 0 50%;
		background-color: #fff;

		z-index: 1;

		list-style: none;
		padding: 0;
		margin: 0;

		overflow: hidden;

		transition: $transition-base-speed box-shadow $transition-base-ease,
			$transition-base-speed opacity $transition-base-ease;

		will-change: opacity, box-shadow;

		@include from(map-get($bp, small)) {
			flex: 0 0 33.333%;
		}

		@include from(map-get($bp, xxlarge)) {
			flex: 0 0 25%;
		}

		&:hover,
		&:focus,
		&:active {
			z-index: 50;
			box-shadow: $shadow-md;
		}

		&--external {

		}
	}

	&__link {
		display: block;
		text-decoration: none;
	}

	&__image-container {
		position: relative;
		overflow: hidden;

		&:after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: ($spacing-vertical-base-sm / 4) * 1.1;

			background-color: yellow;
			opacity: 0;

			transition: $transition-base-speed transform $transition-base-ease;

			transform: translateY(100%);

			will-change: opacity, transform;

			.pages-list__item:hover &,
			.pages-list__item:focus & {
				transform: translateY(10%);
				opacity: 0.85;
			}
		}
	}

	&__image {
		display: block;
		width: 100%;
		height: 100%;

		transform: translateZ(0);
		backface-visibility: hidden;

		transition: $transition-base-speed opacity $transition-base-ease,
		$transition-base-speed filter $transition-base-ease;
		will-change: opacity, filter;
		opacity: 0.8;

		.pages-list__item:hover &,
		.pages-list__item:focus & {
			opacity: 1;
		}
	}

	&__image-bg {
		opacity: 0.8;
	}

	&__content {
		padding: $grid-gutter-sm;

		@include from(map-get($bp, small)) {
			padding: $grid-gutter-md;
		}
	}

	&__title {
		@include heading-base();
		padding: 0;
		margin: 0 0 0.25em 0;
		color: #000;
		font-size: 1.15em;

		@include from(map-get($bp, small)) {
			font-size: 1.5em;
		}
	}

	&__blurb {
		font-size: 0.95em;

		@include to(map-get($bp, small)) {
			display: -webkit-box;
		  -webkit-line-clamp: 4;
		  -webkit-box-orient: vertical;
			overflow: hidden;
			opacity: 0.8;
		}

		@include from(map-get($bp, small)) {
			@include set-font-size(meta);
		}
	}

	&__subtitle {
		padding: 0;
		margin: 0;

		font-family: $mono-font;
		font-size: 0.75em;
		opacity: 0.5;
	}

	&__source {
		@include fancy-link();
	}

}

