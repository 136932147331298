/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number

@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}
	@return $number;
}


@mixin responsive-font-size($min, $max, $fromWidth, $toWidth) {

	$minUnitless: strip-unit($min);
	$maxUnitless: strip-unit($max);

	$fromWidthUnitless: strip-unit($fromWidth);
	$toWidthUnitless: strip-unit($toWidth);

	// Get average size
	$fallbackSize: ($min + $max) / 2;

	font-size: $min;

	@include from-to($fromWidth, $toWidth) {
		font-size: $fallbackSize;
		font-size: calc( #{$min} + (#{$maxUnitless} - #{$minUnitless}) * ((100vw - #{$fromWidth}) / (#{$toWidthUnitless} - #{$fromWidthUnitless})) );
	}

	@include from($toWidth) {
		font-size: $max;
	}

}
