.btn {
	&:hover,
	&:focus,
	&:active {
	}
	
	@include from(map-get($bp, medium)) {
	}
}

.btn-group {
	@include from(map-get($bp, medium)) {
	}
}