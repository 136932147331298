.image-block {
  @include clearfix();
  clear: left;
  width: 100%;

  margin-bottom: $spacing-vertical-base-sm * 1.1;

  * + & {
    margin-top: $spacing-vertical-base-sm;
  }

  &--inline {
    margin-bottom: 0;

    .image-block__item--full {
      padding: 0;
    }

    .image-block__item--half {
      padding-left: 0;
    }

    .image-block__item--half + .image-block__item--half {
      padding-right: 0;
    }

    * + & {
      margin-top: -$spacing-vertical-base-sm * 0.5;
    }

  }

  &--right {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  &__item {

    @include clearfix();
    @include grid-col(12);

    position: relative;
    z-index: $zi-layer-1;

    & + & {
      margin-top: $spacing-vertical-base-sm;
    }

    &--iphone4 {

      .image-block__image {
        display: block;
        width: 85.5%;
        margin: auto;
      }

      .image-block__content {
        position: relative;
        padding: 36% 0 36% 0;

        box-shadow: none;

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 10%;
          left: 5%;
          bottom: 10%;
          right: 5%;
          background-color: black;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: url('/assets/img/iphone4.png') no-repeat;
          background-size: 100% 100%;
          z-index: $zi-layer-3;
        }
      }


    }

    &--colour-splash {

      .image-block__image {
        box-shadow: none;
      }

      .image-block__content {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          background-color: $color-highlight;
          filter: blur(20px);
          transform: translate(10px, 10px);

          z-index: -1;
        }
      }
    }

    &--primary {
      .image-block__content:before {
        background-color: $color-highlight;
      }
    }

    &--no-shadow {
      .image-block__content {
        box-shadow: none;
      }

      .image-block__image {
        background-color: transparent;
      }
    }

  }

  &,
  .page__content & {

    &__title,
    &__subtitle {
      line-height: 1.3;
      vertical-align: baseline;
      margin: ($spacing-vertical-base-sm * 0.5) 0;

      &:first-child {
        margin-top: 0;
      }
    }

    &__title {
      @include heading-mono();
      font-size: 1em;
      color: #000;
    }

    &__subtitle {
      @include heading-mono();
      @include set-font-size(meta);
      color: #000;
    }

    &__title + &__subtitle {
      margin-top: -($spacing-vertical-base-sm * 0.25);
      opacity: 0.5;
    }
  }

  &__content {
    display: block;
    max-height: 80vh;
    overflow: hidden;
    box-shadow: $shadow-md;
  }

  &__image {
    position: relative;
    display: block;
    width: 100%;
    height: auto;

    background-color: desaturate(lighten($color-secondary-dark, 35%), 35%);

    z-index: $zi-layer-1;
  }

}

@include to(map-get($bp, small)) {
  .image-block {
    &__item {
      &--small {
        @include grid-col(6);
      }
    }
  }
}

@include from(map-get($bp, small)) {

  .image-block {

    margin-bottom: $spacing-vertical-base / 4;

    * + & {
      margin-top: $spacing-vertical-base / 4;
    }

    * + &--no-gap {
      margin-top: 0;
    }

    &--center {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    &__item {

      @include grid-col(10);

      &--auto {
        float: none;
        width: auto;
        margin: auto;
      }

			&--full {
				width: 100%;
				margin: 0;
			}

			&--content {
				@include grid-prefix(2);
				@include grid-suffix(2);
				@include grid-col(8);
			}

      &--half,
      &--third,
      &--quarter,
      &--fifth,
      &--large {
        & + & {
          margin-top: 0;
        }
      }

      &--large {
        @include grid-col(11);
        float: none;
        max-width: 1200px;
        margin: auto;
      }

      &--half {
        @include grid-col(6);
      }

      &--third {
        @include grid-col(4);
      }

      &--quarter {
        @include grid-col(5);

        &:nth-child(3n) {
          margin-left: 15%;
        }
      }

      &--fifth {
        @include grid-col(3);
      }


      &--lower {
        padding-top: $spacing-vertical-base / 2;
      }

      &--lower-more {
        padding-top: $spacing-vertical-base;
      }

      &--end {
        align-self: flex-end;
      }
    }

    &__title,
    &__subtitle {

      flex: 1;

      &--center {
        text-align: center;
      }

      &--right {
        text-align: right;
      }
    }

    &__content {
      max-height: none;
    }

  }

}


@include from(map-get($bp, medium)) {

  .image-block {

    &__item {

			&--content {
				@include grid-prefix(2.75);
				@include grid-suffix(2.75);
				@include grid-col(6.5);
			}

      &--quarter {
        @include grid-col(3);

        &:nth-child(3n),
        &:nth-child(4n) {
          margin-left: auto;
          margin-top: $spacing-vertical-base / 2;
        }
      }

      &--fifth {
        @include grid-col(12/5);
      }

    }

    &__title--right,
    &__subtitle--right {

      &, .content & {
        margin-left: 100px; // hacky logo width
      }

    }

  }

}
