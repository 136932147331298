@mixin selection($color) {

  ::selection {
  	background: $color;
  }

  ::-moz-selection {
  	background: $color;
  }

}
