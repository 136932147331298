$circle_height_mobile: 350px;

$odd: 'nth-of-type(2n+1)';
$even: 'nth-of-type(2n)';

.project {
  position: relative;
  margin: $grid-gutter-sm;
  max-width: $grid-max-width;
  text-decoration: none;

  @include from(map-get($bp, small)) {
    display: flex;
    align-items: center;
    margin: auto;
    max-width: 66%;
    height: 90vh;
    max-height: 800px;
    min-height: 500px;

    &:#{$even} {
      justify-content: flex-end;
      .project__image {
        order: 2;
      }
    }
  }

  &__image,
  &__content {
    @include from(map-get($bp, small)) {
      flex: 0 0 50%;
      margin: 0;
    }
  }

  &__image {
    position: relative;
    pointer-events: none;
    min-height: $circle_height_mobile;
  }

  &__content {
    z-index: $zi-layer-3;

    @include from(map-get($bp, small)) {
      max-width: 30rem;
      margin: 0 $grid-gutter-sm;
    }
  }

  &__detail {
    @include set-font-size(meta);
    font-family: $mono-font;
    margin: 0 0 1em 0;
  }

  &__title {
    @include heading-base();
    @include set-font-size(h2);
    color: #000;
    margin: 0 0 0.5em 0;

    @include from(map-get($bp, small)) {
      margin-left: $heading-lhs-kerning-tweak;
    }
  }

  &__link {
    @include fancy-link();
    @include set-font-size(meta);
    display: inline;
    font-family: $mono-font;
    padding: 0.25em 0;
  }

  object a {
    @include fancy-link();
    padding: 0.25em 0;
  }
}

/* Floating images */

.project__floating-image {
  position: absolute;
  z-index: $zi-layer-2;
  box-shadow: $box-shadow-base;

  border-radius: 6px;
  overflow: hidden;

  & > img {
    display: block;
    width: 100%;
    height: auto;
    background-color: #fff;
  }

  &.with3 {

    top: 0;
    left: 0;

    width: 120px;

    &:nth-child(1) {
      transform: translate(30%, 40%) scale(0.93);
    }

    &:nth-child(2) {
      transform: translate(80%, 50%) scale(0.98);
    }

    &:nth-child(3) {
      transform: translate(130%, 60%);
    }
  }

  @include from (map-get($bp, small)) {

    &.with3 {
      top: 50%;
      left: 50%;

      width: 50%;
      min-width: 150px;
      max-width: 280px;

      &:nth-child(1) {
        transform: translate(-150%, -55%) scale(0.93);
      }

      &:nth-child(2) {
        transform: translate(-90%, -40%) scale(0.98);
      }

      &:nth-child(3) {
        transform: translate(-30%, -25%);
      }
    }

  }

}

/* Floating devices */

.project__floating-device {

  position: absolute;
  top: 0;
  left: 0;

  z-index: $zi-layer-2;

  box-shadow: $box-shadow-base;
  transform-origin: center center;

  & > img {
    display: block;
    width: 100%;
    height: auto;
  }

  &--mobile {

    $width: 150px;
    $ratio: 1.9; // height to width

    top: 50%;
    left: 50%;

    width: $width;
    padding-top: $width * $ratio;

    border-radius: 1vw;
    overflow: hidden;
    backface-visibility: hidden;

    border: 0.2vw solid #000;
    background-color: #000;

    box-sizing: content-box;
    box-shadow: $box-shadow-stronger;

    transform: translate(-30%, -40%);
    transform-origin: top left;

    &.white {
      border-color: #fff;
      background-color: #fff;
      box-shadow: $box-shadow-mid;
    }

    & > img {
      position: absolute;
      top: 0;
      overflow: hidden;
    }

    .project:#{$even} & {
      transform: translate(-80%, -40%);
    }

    @include from (map-get($bp, small)) {
      top: 50%;
      left: 50%;

      .project:#{$odd} & {
        transform: translate(-50%, -50%);
      }

      .project:#{$even} & {
        transform: translate(-50%, -50%);
      }
    }

    @include from (map-get($bp, medium)) {
      $width: 200px;
      width: $width;
      padding-top: $width * $ratio;
    }

    @include from (map-get($bp, large)) {
      $width: 250px;
      width: $width;
      padding-top: $width * $ratio;
    }
  }

  &--desktop {
    $width: 250px;
    $ratio: 0.7; // height to width

    left: 0;
    width: $width;
    padding-top: $width * $ratio;

    overflow: hidden;
    overflow-y: scroll;
    backface-visibility: hidden;

    border-radius: 3px;

    background-color: #000;
    box-shadow: $box-shadow-stronger;

    & > img {
      position: absolute;
      top: 0;
      left: -1%;
      width: 102%;

      padding-top: 14px;
    }

    @include from (map-get($bp, small)) {
      $width: 250px;
      width: $width;
      padding-top: $width * $ratio;
    }

    @include from (map-get($bp, medium)) {
      $width: 330px;
      width: $width;
      padding-top: $width * $ratio;
    }
  }

  &--desktop-dots {
    display: block;
    position: absolute;
    top: 4px;
    left: 5px;
    width: 1em;
    height: 5px;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg width="163" height="35" viewBox="0 0 163 35" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="#ED6A5E" cx="17.5" cy="17.5" r="17.5"/><circle fill="#F5BE4F" cx="81.5" cy="17.5" r="17.5"/><circle fill="#62C655" cx="145.5" cy="17.5" r="17.5"/></g></svg>');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 200;
  }

}

@include from(map-get($bp, small)) {

  .project {

    &__title {
      margin-left: $heading-lhs-kerning-tweak;
    }

    &:#{$even} {

      justify-content: flex-end;

      .project__image {
        order: 2;
      }
    }

  }

}

/* Floating outlined shapes */

.project__floating-outlined-shape {
  position: absolute;
  z-index: $zi-layer-2;

  .project:nth-of-type(2n + 1) & {
    position: absolute;
    bottom: 5%;
    right: 30%;

    use {
      stroke: $color-tertiary-bright;
    }

    @include from(map-get($bp, small)) {
      bottom: 0;
      right: 0;
      transform: translate(-150%, -10%);
    }
  }

  .project:#{$even} & {
    position: absolute;
    bottom: 13%;
    left: -44%;

    use {
      stroke: $color-primary;
    }

    @include from(map-get($bp, small)) {
      bottom: 5%;
      right: 30%;
    }
  }
}

/* Big circles */

.shape {

  .project & {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0 -6.5%; // to allow for space around circle

    width: $circle_height_mobile;
    height: $circle_height_mobile;
  }

  .project:#{$odd} & {
    left: -25%;
  }

  .project:#{$even} & {
    right: -25%;
  }

  @include from(map-get($bp, small)) {

    .project & {
      width: 50vw;
      height: 50vw;
      min-width: 200px;
      min-height: 200px;
      max-width: 900px;
      max-height: 900px;
    }

    .project:#{$odd} & {
      left: auto;
      right: 30%;
    }

    .project:#{$even} & {
      left: 30%;
      right: auto;
    }
  }
}

