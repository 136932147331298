@mixin heading-base() {
	font-family: $heading-font;
	font-weight: 800;

	letter-spacing: $letter-spacing-heading;
	line-height: $line-height-heading;
}


@mixin heading-mono() {
	font-family: $mono-font;
	font-weight: normal;
}
