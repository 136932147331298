/* ----------------------------
   Font sizes
   ---------------------------- */

$font-stacks: (
	h1_title: (	mobile: 3.3em, 		small: 4em, 		medium: 5em ),
	h1:				(	mobile: 3em, 			small: 3.75em, 	medium: 4em	),
	h2: 			(	mobile: 1.7em, 	small: 1.85em, 	medium: 2.4em	),
	h3: 			(	mobile: 1.3em, 		small: 1.3em, 	medium: 1.4em),
	lede:			( mobile: 1.1em ),
	meta: 		( mobile: 1em,		small: 0.8em ),
	content:  ( mobile: 1em,			small: 1em,		medium: 0.9em )
);

@function get-font-size($name, $scope: mobile) {
	$stack: map-get($font-stacks, $name);
	$size: map-get($stack, $scope);
	@return $size;
}

@mixin set-font-size($name) {
	$stack: map-get($font-stacks, $name);
	@include set-font-size-stack($stack);
}

@mixin set-font-size-stack($stack) {

	@each $scope, $size in $stack {

		@if ($scope == mobile) {
			font-size: $size;
		}

		@else {
			@include from(map-get($bp, $scope)) {
				font-size: $size;

				@if $size >= 4em {
					margin-left: $heading-lhs-kerning-tweak;
				}
			}
		}

	}

}

/* ----------------------------
   Base typography
   ---------------------------- */

body {
	@include responsive-font-size(16px, 21px, 400px, $grid-max-width);
	font-family: $body-font;
	line-height: $line-height-body;
	color: $color-text;
}

/* ----------------------------
   Typography styles
   ---------------------------- */

h1, h2, h3, h4, h5, p, li {
	&:first-child {
		margin-top: 0;
	}
}

/* separate selection styles so not discarded by browsers */
@include selection($color-selection-bg);

a {
	color: inherit;
}

hr {
	border: 0;
	height: 1.25em;
	margin: 1.5em 0;

	&:before {
		content: "";
		display: block;
		width: 1.1em;
		height: 1.1em;

		background: url(/assets/img/lightning-black.svg) no-repeat;
		background-size: contain;
	}

}

.content {

	// set up base heading tags
	@each $name, $stack in $font-stacks {
		#{$name} {
			@include set-font-size-stack($stack);
		}

	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@include heading-base();
		@include font-smoothing();

		position: relative;
		margin: $spacing-vertical-base-sm 0;

		letter-spacing: $letter-spacing-heading-large;
		color: #000;

		@include from(map-get($bp, small)) {
			margin: ($spacing-vertical-base / 2) 0 ($spacing-vertical-base / 8) 0;
			margin-left: $heading-lhs-kerning-tweak;
		}
		&:first-child {
			margin-top: 0;
		}
	}

	> &:last-child {
		margin-bottom: 0;
	}

	h3, h4 {
		@include from(map-get($bp, small)) {
			margin-top: ($spacing-vertical-base / 4);
		}
	}

	p {
		@include from(map-get($bp, medium)) {
			> &:last-child {
				margin-bottom: 0;
			}
		}
	}

	abbr[title]{
		border-bottom: none;
		text-decoration: none;
	}

	ul, ol, li, p, hr {
		margin: 0 0 1.3em 0;
	}

	p,
	.pages--item {
		break-inside: avoid;
		box-decoration-break: split;
		-webkit-box-decoration-break: split;
	}

	.lede {
		@include font-smoothing();
		@include set-font-size(lede);

		line-height: $line-height-feature;
		letter-spacing: $letter-spacing-p-lede;
	}

	ul,
	li {
		padding: 0;
	}

	ul ul {
		padding-left: 1em;
		break-inside: avoid;

		li:before {
			content: "-";
		}
	}

	li {
		@include heading-mono();
		@include set-font-size(meta);
		position: relative;
		padding-left: 1.3em;
		list-style-type: none;

		&:before {
			position: absolute;
			content: ">";
			top: 0;
			left: 0;
			opacity: 0.5;
		}
	}

	a {
		@include fancy-link;
	}

	b, strong {
		color: #000;
	}


	blockquote {
		@include heading-mono();
		@include set-font-size(meta);
		padding: 0;
		margin: $spacing-vertical-base-sm 0;
		padding-left: $spacing-vertical-base / 8;
		margin-left: -$spacing-vertical-base / 8;

		border-left: 4px solid #000;
	}

	.title {
		margin: 1.8rem;
		line-height: 1.75rem;
	}

	.section-title {
		@include heading-mono();
		font-size: 1em;
	}

	.section-title--caps {
		@include heading-mono();
		@include set-font-size(meta);

		margin: ($spacing-vertical-base / 4) 0 ($spacing-vertical-base / 8) 0;

		text-transform: uppercase;
		letter-spacing: $letter-spacing-uppercase;
	}

}
