@mixin colour-scheme($color, $color-link-hover: $color-highlight, $inverse: true){

  @if ($inverse) {

    @include font-smoothing();

    background-color: $color;
    //color: $color-bg;

    h1, h2, h3 {
      color: inherit;
    }

    .content {

      a {
        @include fancy-link(false, $color, $color-link-hover);
      }

    }
  }

}
