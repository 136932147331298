.shape {
	width: $shape-base-size * 0.75;
	height: $shape-base-size * 0.75;
	z-index: $zi-layer-1;

	&__svg {
		width: 100%;
		height: 100%;
	}

	&--bg {

	}

	&--shape {
		filter: blur(1rem);
	}

	&--solid {
		filter: blur(1rem);
	}

	&--solid-primary {
		fill: $color-primary;
	}

	&--solid-secondary {
		fill: $color-secondary;
	}

	&--solid-tertiary {
		fill: $color-tertiary;
	}

}

@include to(map-get($bp, small)) {

	.shape {
		width: $shape-size-mobile;
		height: $shape-size-mobile;
		transform: translate(-25%, -25%);
	}

}


@include from(map-get($bp, small)) {

	.shape {

		&--shape {
			filter: blur(2rem);
		}
	}

}

@include from(map-get($bp, large)) {

	.shape {
		width: $shape-base-size;
		height: $shape-base-size;
	}

}
